import React, { useEffect, useState, useRef } from 'react';
import './EventTimeLine.css';
import {API_URL} from '../../const';

const EventTimeLine = ({ visheshamData }) => {
    const [time, setTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [progress, setProgress] = useState(0);

    const brideRef = useRef(null);
    const groomRef = useRef(null);
    const timelineLineRef = useRef(null);

    function parseDateString(dateStr) {
        const [datePart, timePart] = dateStr.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds); // month is zero-based
    }

    const defaultEventDate = new Date();
    const defaultCreationDate = new Date();

    const eventDate = visheshamData?.event_start_date ? parseDateString(visheshamData.event_start_date) : defaultEventDate;
    const creationDate = visheshamData?.created_on ? parseDateString(visheshamData.created_on) : defaultCreationDate;

    const groom_png = visheshamData?.groom_png ? visheshamData.groom_png : "";
    const bride_png = visheshamData?.bride_png ? visheshamData.bride_png : "";

    useEffect(() => {
        function updateCountdown() {
            const now = new Date();
            const isFutureEvent = eventDate > now; // Check if the event is in the future
            const timeDifference = isFutureEvent ? eventDate - now : now - eventDate;
        
            const containerWidth = brideRef.current.parentElement.offsetWidth;
            const maxMoveDistance = containerWidth * 0.40; // 40% from the center for each image
            const minGap = containerWidth * 0.10; // 10% gap between the images
        
            // Calculate countdown time
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
        
            setTime({ days, hours, minutes, seconds });
        
            // Calculate progress for the timeline
            const totalDuration = eventDate - creationDate;
            const elapsedTime = isFutureEvent
                ? Math.min(now - creationDate, totalDuration)
                : totalDuration; // Full progress if the event is past
        
            const newProgress = isFutureEvent ? elapsedTime / totalDuration : 1;
        
            setProgress(newProgress);
        
            // Position the images
            if (brideRef.current && groomRef.current) {
                // Calculate the movement distance based on progress
                const moveDistance = maxMoveDistance * newProgress;
        
                // Ensure the images remain in their closest position once the event is past
                const finalMoveDistance = isFutureEvent
                    ? Math.min(moveDistance, maxMoveDistance - minGap / 2)
                    : maxMoveDistance - minGap / 2;
        
                brideRef.current.style.transform = `translateX(${finalMoveDistance}px)`;
                groomRef.current.style.transform = `translateX(-${finalMoveDistance}px)`;
            }
        }
        
    
        const countdownInterval = { current: null };
        countdownInterval.current = setInterval(updateCountdown, 1000);
        return () => clearInterval(countdownInterval.current);
    }, [eventDate, creationDate]);
    
    
    
    
    

    return (
        <div className="timelineContainer">
            <div className="countdown-container">
                <div className="countdown-item">
                    <div className="countdown-number">{time.days}</div>
                    <div className="countdown-label">Days</div>
                </div>
                <div className="countdown-item">
                    <div className="countdown-number">{time.hours}</div>
                    <div className="countdown-label">Hours</div>
                </div>
                <div className="countdown-item">
                    <div className="countdown-number">{time.minutes}</div>
                    <div className="countdown-label">Minutes</div>
                </div>
                <div className="countdown-item">
                    <div className="countdown-number">{time.seconds}</div>
                    <div className="countdown-label">Seconds</div>
                </div>
            </div>

            <div className="images-container">
                <img ref={brideRef} src={API_URL + bride_png} alt="Bride" className="image bride" />
                <img ref={groomRef} src={API_URL + groom_png} alt="Groom" className="image groom" />
            </div>
            
            <div className="timeline-container">
                <div className="timeline-line" ref={timelineLineRef}></div>
                <div id="start-date" className="timeline-label date-label"></div>
                <div id="middle-date" className="timeline-label date-label"></div>
                <div id="end-date" className="timeline-label date-label"></div>
            </div>
        </div>
    );
};

export default EventTimeLine;
